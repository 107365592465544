<template>
  <div>
    <PageDynamicBreadcrumb custom-current-page="Overview" />
    <PageHeader title="Vehicles Overview" />

    <DataTable
      :table-key="tableKey"
      row-key="product_id"
      title="Active Vehicles"
      :columns="columns"
      :rows="vehicles"
      :loading="loading"
      :pagination="pagination"
      :downloadable="false"
      :refreshable="true"
      :customizable="true"
      :filterable="true"
      :server-side="true"
      :searchable="true"
      :disable-state="false"
      :dimension-filter-options-by-column="dimensionFilterOptionsByColumn"
      @update-rows="val => getVehicles(val)"
    >
      <template v-slot:image_url="row">
        <a :href="row.images[0]" target="_blank">
          {{
            row.images[0].length > 40
              ? row.images[0].substr(0, 40) + "..."
              : row.images[0]
          }}
        </a>
      </template>
      <template v-slot:destination_url="row">
        <a
          :href="
            row.destination_url.length > 0
              ? row.destination_url
              : 'https://www.carscience.com/listing/' + row.product_id
          "
          target="_blank"
        >
          {{
            row.destination_url.length > 0
              ? row.destination_url.length > 40
                ? row.destination_url.substr(0, 40) + "..."
                : row.destination_url
              : "https://www.carscience.com/listing/" + row.product_id
          }}
        </a>
      </template>
    </DataTable>
  </div>
</template>

<script>
import PageHeader from "@/components/UI/PageHeader";
import PageDynamicBreadcrumb from "@/components/UI/PageDynamicBreadcrumb";
import { QImg } from "quasar";
import axios from "axios";
import DataTable from "@/components/DataTable";
import DataTableMixin from "@/mixins/DataTableMixin";

export default {
  name: "InventoryVehiclesOverview",
  mixins: [DataTableMixin],
  components: {
    DataTable,
    PageDynamicBreadcrumb,
    PageHeader
  },
  data() {
    return {
      loading: true,
      tableKey: "vehiclesOverview",
      vehicles: [],
      pendingGetVehiclesRequests: [],
      pagination: {
        sortBy: "product_id",
        descending: true,
        page: 1,
        rowsPerPage: 10,
        rowsNumber: 1000
      },
      dimensionFilterOptionsByColumn: {},
      columns: [
        {
          name: "business_id",
          label: "Business ID",
          field: "business_id",
          type: "dimension",
          sortable: false,
          visible: false
        },
        {
          name: "business_name",
          label: "Business",
          field: "business_name",
          type: "dimension",
          sortable: false,
          visible: false
        },
        {
          name: "inventory_source_id",
          label: "Inventory Source ID",
          field: "inventory_source_id",
          type: "dimension",
          sortable: false,
          visible: false
        },
        {
          name: "inventory_source_name",
          label: "Inventory Feed",
          field: "inventory_source_name",
          type: "dimension",
          sortable: false,
          visible: false
        },
        {
          name: "product_id",
          label: "Product ID",
          field: "product_id",
          type: "dimension",
          sortable: true,
          filterable: false
        },
        {
          name: "image",
          label: "Image",
          hideField: true,
          isComponentField: true,
          field: row => ({
            component: QImg,
            componentProperties: {
              src: row.images[0],
              fit: "contain",
              width: "70px",
              style: "width: 70px; height: 50px;"
            },
            componentBody: ""
          }),
          sortable: false,
          type: "dimension",
          filterable: false
        },
        {
          name: "image_url",
          label: "Image URL",
          field: row => row.images[0],
          hideField: true,
          sortable: false,
          filterable: false,
          type: "dimension",
          visible: false
        },
        {
          name: "image_count",
          label: "Image Count",
          field: row => row.images.length,
          sortable: true,
          filterable: false,
          type: "metric",
          metricType: "number",
          visible: false
        },
        {
          name: "vin",
          label: "VIN",
          field: "vin",
          sortable: true,
          filterable: false
        },
        {
          name: "year",
          label: "Year",
          field: "year",
          sortable: true,
          type: "metric",
          metricType: "year"
        },
        {
          name: "make",
          label: "Make",
          field: "make",
          sortable: true,
          type: "dimension"
        },
        {
          name: "model",
          label: "Model",
          field: "model",
          sortable: true,
          type: "dimension"
        },
        {
          name: "trim",
          label: "Trim",
          format: row => (row.trim.length > 0 ? row.trim : "-"),
          sortable: false,
          filterable: false,
          type: "dimension",
          visible: false
        },
        {
          name: "body_type",
          label: "Body Type",
          field: "body_type",
          sortable: true,
          type: "dimension"
        },
        {
          name: "condition",
          label: "Condition",
          field: "condition",
          sortable: true,
          type: "dimension"
        },
        {
          name: "price",
          label: "Price",
          field: "price",
          format: val =>
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD"
            }).format(val),
          sortable: true,
          type: "metric",
          metricType: "number"
        },
        {
          name: "mileage",
          label: "Mileage",
          field: "mileage",
          format: val => new Intl.NumberFormat().format(val),
          sortable: true,
          type: "metric",
          metricType: "number"
        },
        {
          name: "drivetrain",
          label: "Drivetrain",
          field: "drivetrain",
          format: val => (val.length > 0 ? val : "-"),
          sortable: true,
          type: "dimension",
          visible: false
        },
        {
          name: "transmission",
          label: "Transmission",
          field: "transmission",
          sortable: true,
          type: "dimension",
          visible: false
        },
        {
          name: "engine_type",
          label: "Engine Type",
          field: "engine_type",
          sortable: true,
          type: "dimension",
          visible: false
        },
        {
          name: "fuel_type",
          label: "Fuel Type",
          field: "fuel_type",
          sortable: true,
          type: "dimension",
          visible: false
        },
        {
          name: "exterior_color",
          label: "Ext. Color",
          field: "exterior_color",
          sortable: true,
          type: "dimension"
        },
        {
          name: "interior_color",
          label: "Int. Color",
          field: "interior_color",
          sortable: true,
          filterable: false,
          type: "dimension",
          visible: false
        },
        {
          name: "destination_url",
          label: "Destination URL",
          field: row =>
            row.destination_url.length > 0
              ? row.destination_url
              : "https://www.carscience.com/listing/" + row.product_id,
          hideField: true,
          sortable: false,
          filterable: false
        },
        {
          name: "dealer_id",
          label: "Dealer ID",
          field: row => row.dealer.id,
          sortable: true,
          filterable: false,
          type: "dimension"
        },
        {
          name: "dealer_name",
          label: "Dealer",
          field: row => row.dealer.name,
          sortable: true,
          filterable: false,
          type: "dimension"
        },
        {
          name: "dealer_city",
          label: "City",
          field: row => row.dealer_city,
          sortable: true,
          filterable: true,
          type: "dimension"
        },
        {
          name: "dealer_state",
          label: "State",
          field: row => row.dealer_state,
          sortable: true,
          type: "dimension"
        },
        {
          name: "dealer_postal_code",
          label: "Postal Code",
          field: row => row.dealer_postal_code,
          sortable: true,
          filterable: false,
          type: "dimension"
        }
      ]
    };
  },
  beforeMount() {
    // Account for possible business id / business inventory id initial filters params
    let initialUrlParams = new URLSearchParams(window.location.search);

    let initialFilteredBusinessId = initialUrlParams.get("business_id");
    let initialFilteredInventorySourceId = initialUrlParams.get(
      "inventory_source_id"
    );
    if (initialFilteredBusinessId || initialFilteredInventorySourceId) {
      window.history.replaceState(history.state, "", "?");

      this.columns = this.columns.map(column => {
        if (column.name === "business_id" && initialFilteredBusinessId) {
          column.filterValues = {
            in: [initialFilteredBusinessId],
            notIn: [],
            gt: "",
            lt: ""
          };
          column.activeFilterCount = 1;
        } else if (
          column.name === "inventory_source_id" &&
          initialFilteredInventorySourceId
        ) {
          column.filterValues = {
            in: [initialFilteredInventorySourceId],
            notIn: [],
            gt: "",
            lt: ""
          };
          column.activeFilterCount = 1;
        }

        return column;
      });
    } else {
      let urlParamDataTableState = this.loadDataTableStateFromURLParam(
        this.tableKey,
        this.columns
      );

      this.columns = urlParamDataTableState.columns;

      if (Object.keys(urlParamDataTableState.pagination).length > 0) {
        this.pagination = urlParamDataTableState.pagination;
      }
      if (urlParamDataTableState.searchFilter.length > 0) {
        this.searchFilter = urlParamDataTableState.searchFilter;
      }
    }
  },
  mounted() {
    this.getVehicles();
  },
  methods: {
    getVehicles(dataTableState = null) {
      this.loading = true;

      // If we don't have a dataTableState (passed in events emitted by the datatable), then fallback to default initial request.
      if (!dataTableState) {
        dataTableState = {
          columns: this.columns,
          pagination: this.pagination,
          searchFilter: ""
        };
      }

      // Abort any existing requests
      if (this.pendingGetVehiclesRequests.length > 0) {
        this.pendingGetVehiclesRequests.forEach(controller => {
          controller.abort();
        });
        this.pendingGetVehiclesRequests = [];
      }
      let controller = new AbortController();
      let { signal } = controller;
      this.pendingGetVehiclesRequests.push(controller);

      // Configure current request filter params
      let filterParams = [];

      // Account for all column filters dynamically
      dataTableState.columns
        .filter(c => c.type === "dimension")
        .forEach(column => {
          if (column.activeFilterCount > 0) {
            if (column.filterValues.in.length > 0) {
              filterParams.push({
                [column.name]: { in: column.filterValues.in }
              });
            }
            if (column.filterValues.notIn.length > 0) {
              filterParams.push({
                [column.name]: { not_in: column.filterValues.not_in }
              });
            }
          }
        });

      dataTableState.columns
        .filter(c => c.type === "metric")
        .forEach(column => {
          if (column.activeFilterCount > 0) {
            if (column.filterValues.gt.length > 0) {
              filterParams.push({
                [column.name]: { gte: +column.filterValues.gt }
              });
            }
            if (column.filterValues.lt.length > 0) {
              filterParams.push({
                [column.name]: { lte: +column.filterValues.lt }
              });
            }
          }
        });

      const sorting = [];
      if (dataTableState.pagination.sortBy) {
        sorting.push({
          [dataTableState.pagination.sortBy]: dataTableState.pagination.descending ? "desc" : "asc"
        });
      }

      const facet_fields_obj = {};
      dataTableState.columns
        .filter(c => c.type === "dimension")
        .map(c => {
          facet_fields_obj[c.name] = [];
          return c.name;
        });

      const searchObj = {
        product_type: "vehicles",
        filters: filterParams,
        options: {
          response_fields: dataTableState.columns
            .filter(c => c.visible)
            .map(c => c.name),
          faceting: {
            fields: facet_fields_obj,
            size: 50
          }
        },
        pagination: {
          page: dataTableState.pagination.page,
          per_page: dataTableState.pagination.rowsPerPage
        },
        sorting,
        scoring: []
      };

      if (dataTableState.searchFilter.length > 2) {
        searchObj.options.keyword_search = {
          keyword: dataTableState.searchFilter,
          fields: [
            "exterior_color",
            "make",
            "model",
            "title",
            "vin",
            "product_id_search",
            "dealer_city",
            "dealer_state",
            "dealer_postal_code"
          ],
          type: "most_fields"
        };
        // Ensure score is first in sorting
        searchObj.sorting.unshift({ _score: "desc" });
      }

      axios
        .post("https://api-v2.jumpfeed.com/internal/products", searchObj, {
          headers: {
            "Content-Type": "application/json"
          },
          signal: signal
        })
        .then(response => {
          this.vehicles = response.data.data.results;

          let totalVehicles = parseInt(response.data.data.totals.results);
          this.pagination.rowsNumber =
            totalVehicles > 10000 ? 10000 : totalVehicles;
          this.pagination.totalRows = totalVehicles;

          this.dimensionFilterOptionsByColumn = {};
          let facetsByColumn = response.data.metadata.facets;
          Object.keys(facetsByColumn).forEach(column => {
            this.dimensionFilterOptionsByColumn[column] = facetsByColumn[column]
              .filter(f => f.key.toString().length > 0)
              .map(facet => ({
                label: facet.key,
                value: facet.key
              }));
          });

          this.loading = false;
        })
        .catch(err => {
          if (axios.isCancel(err)) {
            return;
          }
          this.loading = false;
        });
    }
  }
};
</script>

<style scoped lang="scss"></style>
